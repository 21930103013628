import { ref, getCurrentInstance, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import defaultUserImg from '@/assets/image/headImg.jpg'

function useUserCard() {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const route = useRoute()
  const userInfo = JSON.parse(window.localStorage.getItem('adminInfo'))
  const userType = JSON.parse(window.localStorage.getItem('adminInfo')).userType

  const goPersonalInfo = () => {
    router.push('/admin/personalInfo')
  }

  const goTermsService = () => {
    router.push('/admin/termsService')
  }

  const onCommand = (name) => {
    switch (name) {
      case 'personalInfo':
        goPersonalInfo()
        break
      case 'termsService':
        goTermsService()
        break

      case 'exit': {
        const listExamTaskAddUpdatePath = [
          'task/update',
          'task/add',
          'exam/add',
          'exam/update',
        ]
        let isExamtaskAddUpdatePage = false
        listExamTaskAddUpdatePath.forEach((path) => {
          if (route.fullPath.includes(path)) {
            isExamtaskAddUpdatePage = true
          }
        })
        if (isExamtaskAddUpdatePage) {
          proxy.$emit('log-out')
          return
        }
        outLogin()
        break
      }
      case 'updatePwd':
        updatePwdMask.value = true
        break
    }
  }
  const outLogin = async () => {
    let res = await $.Axios({
      url: '/admin/sys/user/outLogin?' + encrypt.encryptUrlData(),
    })
    if (res.ok) {
      $.clearPersonalStorageData()
      $.notifyMsg(res.message, 'suc', proxy.$t('tips.sucTitle'))
      router.push('/')
    }
  }

  let updatePwdMask = ref(false)
  let pwdData = ref({
    oldPwd: '',
    password: '',
    verPwd: '',
  })
  const showPwd = ref({
    oldPwd: false,
    password: false,
    verPwd: false,
  })

  let pwdRules = computed(() => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,32}$/
    return {
      oldPwd: [
        {
          required: true,
          message: proxy.$t('accountTxt.verTxt.password'),
          trigger: 'blur',
        },
      ],
      password: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(proxy.$t('accountTxt.verTxt.password'))
            } else if (!regex.test(value)) {
              callback('Mật khẩu không đúng định dạng')
            } else if (value == pwdData.value.oldPwd) {
              callback('Mật khẩu mới không được giống mật khẩu cũ')
            }
            callback()
          },
          trigger: 'blur',
        },
      ],
      verPwd: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(proxy.$t('accountTxt.verTxt.confirmPwd'))
            } else if (!regex.test(value)) {
              callback('Mật khẩu không đúng định dạng')
            } else if (value != pwdData.value.password) {
              callback('Nhập lại mật khẩu không trùng khớp')
            }
            callback()
          },
          trigger: 'blur',
        },
      ],
    }
  })

  const updateUserPassword = async () => {
    // 修改密码 Change the password
    proxy.$refs['pwdForm'].validate(async (valid) => {
      if (valid) {
        let urlData = {
          oldPwd: encrypt.encryptParams(pwdData.value.oldPwd),
          newPwd: encrypt.encryptParams(pwdData.value.password),
          confirmPwd: encrypt.encryptParams(pwdData.value.verPwd),
        }
        let res = await $.Axios({
          url:
            '/admin/sys/user/updateThisUserPassword?' +
            encrypt.encryptUrlData(urlData),
        })
        if (res.ok) {
          $.notifyMsg(res.message, 'suc', proxy.$t('tips.sucTitle'))
          closeUpdatePwdMask()
          outLogin()
        }
      } else {
        return false
      }
    })
  }

  const closeUpdatePwdMask = () => {
    //关闭弹窗 Close Windows
    updatePwdMask.value = false
    pwdData.value = {
      oldPwd: '',
      password: '',
      verPwd: '',
    }
  }

  let dropdownChange = ref(false)
  const dropdownChangeFun = (val) => {
    dropdownChange.value = val
  }

  const errorUserImg = (e) => {
    e.target.src = defaultUserImg
  }

  return {
    userInfo,
    userType,
    onCommand,
    errorUserImg,
    outLogin,
    updatePwdMask,
    showPwd,
    updateUserPassword,
    closeUpdatePwdMask,
    dropdownChange,
    dropdownChangeFun,
    pwdRules,
    pwdData,
  }
}

export default useUserCard

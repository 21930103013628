export default {
  headerTxt: {
    maximize: '全屏',
    reduction: '取消全屏',
    personal: '个人中心',
    logout: '退出',
    updatePwd: '修改密码',
    pointMe: '点我',
    oldPassword: '旧密码',
    newPassword: '新密码',
    personalInfo: '个人信息',
    termsService: '服务条款',
    message: '消息',
    notify: '通知',
  },

  loginTxt: {
    title: '登录',
    loginBtn: '登陆',
    verTxt: {
      userName: '请输入用户名',
      userLengthLimt: '用户名长度为6到100位',
      password: '请输入密码',
      lengthLimt: '密码长度为6到100位',
    },
    rememberPassword: '记住密码',
    sliderText: '拖动以完成图片',
    clickRefresh: '点击刷新图片',
    rememberPasswordTxt:
      'Vui lòng liên hệ với Admin trường để được đặt lại mật khẩu',
    hotlineFschoolTxt: 'Hotline Fschool:  0838888966',
    forgetPassword: '忘记密码',
    inputAccountNum: '输入账号',
  },

  placeholder: {
    userName: '请输入用户名',
    password: '请输入密码',
  },

  tips: {
    login: '登陆成功！',
    sucTitle: '成功',
    errTitle: '错误',
    warTitle: '警告',
    infoTitle: '消息',
    pleaseSteNum: '请对每个试题设置分数!',
    prompt: '提示',
    inputCatalogueName: '作业组名称不能为空',
    inputIntTips: '请在做题时长输入正整数',
    emptyMultipleTips: '选最少一个正确答案',
    emptyBlankTips: '请输入填空题的答案',
    saveSuccess: 'Lưu thành công',
    sameValueTIme: 'Thời gian bắt đầu và kết thúc không hợp lệ',
    subjectFail:
      'Thầy / Cô đang thực hiện {2} tập môn <strong>{0}</strong>{1}. Thầy / Cô có chắc chắn muốn tiếp tục?',
    forClass: ' cho lớp <strong>{0}</strong>',
    successTitle: 'thành công',
    note: 'Lưu ý',
    save: 'lưu',
    distributeTask: 'giao bài tập',
    successTitle: 'thành công',
    distributeTaskSuccess: 'Giao bài thành công',
  },

  mainTxt: {
    dashboard: '仪表盘',
  },

  publicTxt: {
    empty: '暂无数据！',
    state: '状态',
    provinceCity: '省/市',
    areaCounty: '区/县',
    addrs: '地址',
    contactInfo: '联系信息',
    operate: '操作',
    type: '类型',
    detailsTxt: '详情',
    cancelBtnTxt: '取消',
    confirmBtnTxt: '确认',
    conserveBtnTxt: '保存',
    downloadExcelBtn: 'Excel 下载',
    downloadExcelTemplateBtn: ' Excel 模板下载',
    uploadExcelBtn: ' Excel 上传',
    activeTxt: '激活',
    notActiveTxt: '未激活',
    confirmDelTxt: '确定删除',
    confirmDelTxt2: '你确定要将学校状态改为未活动并删除学校',
    agreeTxt: '同意',
    resetTxt: '重置',
    addTxt: '添加',
    fileAnalysis: '文件上传解析中...',
    homePage: '概览',
    createTime: '创建时间',
    verTxt: {
      numberOrLetter: '请输入数字或字母',
    },
    importExcel: '导入Excel',
    selectFile: '选择文件',
    notSelectFile: '未选择任何文件',
    upload: '上传',
    record: '记录',
    uploadError: '上传失败',
    lookErrorRecord: '查看错误记录',
    delete: '删除',
    screen: '筛选',
    delAll: '删除全部',
    confirm: '确定',
    errorInfo: '错误信息',
    createPerson: '创造者',
    closeBtn: ' 关闭',
    edit: '编辑',
    numOrder: 'numberic order',
    total: 'total',
    addAll: 'Thêm tất cả',
    choiceAll: 'Chọn tất cả',
    record_correct: 'bản ghi',
    save: 'Lưu',
    areYouSure: 'Bạn có chắc chắn không?',
  },

  schoolTxt: {
    title: '组织管理',
    addBtn: '添加',
    currentSchoolList: '当前学校列表',
    delSchoolList: '已删除学校列表 ',
    schoolName: '学校名称',
    // schoolId: '学校ID',
    schoolLevel: '学校等级',
    nature: '性质',
    schoolSort: '学校分类',
    // schoolNameAndId: '输入学校名称与学校ID',
    inputSchoolName: '输入学校名称',
    addSchoolTitle: '添加学校',
    updateSchoolTitle: '更新学校',
    repName: '代表人名称',
    repMobile: '代表人电话',
    repEMail: '代表人的邮箱',
    contactName: '联系人名称',
    contactMobile: '联系人电话',
    schoolBranchInfo: '学校与学校分支的信息',
    classList: '班级列表',
    schoolAccountList: '学校账号列表',
    backList: '返回学校列表',
    // synchroSchool: '同步学校信息',
    schoolInfo: '学校信息',
    branchList: '学校分支列表',
    schoolBranchName: '学校分支名称',
    schoolBranchId: '学校分支ID',
    schoolBranchAddr: '学校分支地址',
    addBranchTitle: '添加学校分支',
    updateBranchTitle: '更新学校分支',
    delSchool: '删除学校',
    recoverySchool: '恢复学校',
    recoverySchoolTxt: '你确定要恢复学校',
    delBranchSchool: '删除学校分支',
    delBranchSchoolTxt: '你确定要删除学校分支',
    verTxt: {
      provincesCityId: '请选择省/市',
      regionId: '请选择区/县',
      level: '请选择学校等',
      nature: '请选择性质',
      type: '请选择分类',
      schoolName: '请选择学校',
      address: '请输入地址',
      deputyUserName: '请输入代表人名',
      deputyUserTel: '请输入代表人电话',
      schoolBranchName: '请输入学校分支名称',
      schoolBranchId: '请输入学校分支ID',
    },
  },

  classTxt: {
    inputClassNmae: '输入班级名称',
    grade: '年级',
    // synchroClass: '同步班级信息',
    className: '班级名称',
    classId: '班级ID',
    addClassTitle: '添加班级',
    updateClassTitle: '更新班级',
    teacherListTxt: '老师列表',
    studentListTxt: '学生列表',
    verTxt: {
      className: '请输入班级名称',
      className_l_l: '班级名称长度为1到10位',
      classId: '请输入班级ID',
      classId_l_l: '班级ID长度为1到10位',
      grade: '请选择年级',
      teacherId: '请选择老师',
      subjectId: '请选择科目',
      moveToClass: '请选要移动到的班级',
    },
    delClass: '删除班级',
    delClassTxt: '你确定要删除班级',
    distributeTeacher: '分配老师',
    cutClass: '切换班级',
    subject: '科目',
    studentName: '学生名称',
    updateDistributeTxt: '更改教师信息',
    distribute: '分配',
    otherSbject: '其他科目',
    removeTeacher: '移除老师',
    removeTeacherTxt: '确定要从班级中删除教师',
    selectAll: '全选',
    changeClass: '更换班级',
    moveToClass: '移到的班级',
    moveTo: '移到',
    schoolYear: '学年',
    addSchoolYear: '添加新学年',
    oldSchoolYear: '旧学年',
    newSchoolYear: '新学年',
    currentSchoolYear: '当前学年',
    activationSchoolYear: '激活新学年',
    activationTipsFront: '你确认想激活学年',
    activationTipsAfter: '成为当前的学年？这个操作不能撤销。',
    understandAndContinue: '明白并继续',
    subjectAll: '全部科目',
    classTotal: '班级总数',
    teacherTotal: '老师总数',
    classTask: '班级作业',
    classText: '班级',
    classInfoText: '班级信息',
    distributionTask: '已分发的作业',
    noScoreTask: '未打分的作业',
    nameSelectTxt: '按名称搜素',
    downloadList: '下载列表',
    backClassHome: '返回主页',
    classManage: '班级管理',
    distributionExcercise: 'Bài tập đã giao',
    loadingTxt: '加载中...',
    baselineTxt: '没有更多',
    upStaire: 'Lên lớp',
    repetition: 'Lưu ban',
    nextClass: 'Lớp chuyển lên',
    noNextClass: 'Không có lớp chuyển lên',
    repetitionClass: 'Lớp lưu ban',
    notRepetition: 'Không lưu ban',
    graduate: 'Ra trường',
    levelUpConfirmTxt:
      'Bạn có chắc chắn muốn thực hiện việc lên lớp/lưu ban không?',
  },

  accountTxt: {
    controller: '管理者',
    teacherTxt: '老师',
    studentTxt: '学生',
    addController: '添加管理者',
    activeTxt: '活跃',
    notActiveTxt: '不活跃',
    addControllerTxt: '添加管理者账户',
    updateControllerTxt: '修改管理者账户',
    updateControllerState: '更换管理者状态',
    updateControllerStateTxt: '你确认想更换状态吗？',
    // updateControllerStateTxt: '你确认想更换状态成不活动吗？',
    delController: '删除管理者',
    delControllerTxt: '你确认想删除管理者吗?',
    updatePasswor: '修改密码',
    verTxt: {
      userName: '请输入用户名',
      userName_l_l: '用户名长度为6到32位',
      password: '请输入密码',
      password_l_l: '密码长度为6到32位',
      confirmPwd: '请再次输入密码',
      atypism: '两次密码不一致',
      name: '请输入姓名',
      userKey: '请输入学员ID',
      classId: '请选择班级',
      school: '请选择学校',
      syId: '请选择学年',
    },
    teacherId: '老师ID',
    inputTeacherId: '输入老师ID',
    addTeacherTxt: '添加老师账户',
    updateTeacherTxt: '修改老师账户',
    updateTeacherState: '更换老师状态',
    updateTeacherStateTxt: '你确认想更换状态吗？',
    // updateTeacherStateTxt: '你确认想更换状态成活动吗？',
    delTeacher: '删除老师',
    delTeacherTxt: '你确认想删除这位老师吗？ ',
    studentId: '学生ID',
    inputStudentId: '输入学生ID',
    birthday: '出生日期',
    gender: '性别',
    men: '男',
    women: '女',
    addStudentTxt: '添加学生账号',
    updateStudentTxt: '修改学生账号',
    updateStudentState: '更换学生状态',
    updateStudentStateTxt: '你确认想更换状态吗？',
    delStudentTxt: '你确认想删除这位学生吗？',
    delStudent: '删除学生',
    teacherName: '老师名称',
  },

  userTxt: {
    userTxt: '用户',
    title: '用户管理',
    accountManage: '账号管理',
    administrators: '管理员',
    roleManage: '角色管理',
    addBtn: '创建',
    inputName: '输入姓名',
    inputUserNmae: '输入用户名',
    role: '角色',
    name: '姓名',
    userName: '用户名',
    roleName: '角色名称',
    accountNum: '分配了角色的帐号',
    addMaskTitle: '添加 Admin账号',
    updateMaskTitle: '修改 Admin账号',
    passwordTxt: '密码',
    confirmPwdTxt: '确认密码',
    canVisitSchool: '可访问的学校',
    addRoleTitle: '添加角色名称',
    updateRoleTitle: '修改角色名称',
    email: '邮箱',
    mobile: '手机号',
    allTxt: '全部',
    partTxt: '部分',
    delUser: '删除Admin账号',
    delUserTxt: '您确定要删除此管理员帐户吗？',
    delRole: '删除角色',
    delRoleTxt: '你确定要删除此角色吗？',
    updateUserState: '更换 Admin状态',
    updateUserStateTxt: '你确认想更换状态吗？',
    updateUserStateTxt_y: '你确认想更换状态成活跃?',
    updateUserStateTxt_n: '你确认想更换状态成不活跃?',
    verTxt: {
      roleName: '请输入角色名称',
      roleName_l_l: '角色名称长度为1到32位',
    },
    find: '找到',
    aResult: '个结果',
    pwdTxt: '密码',
    studentInfo: '学生信息',
    parentInof: '家长信息',
    parentName: '家长名字',
    parentMobile: '家长的电话号码',
    parentEmail: '家长邮件',
    confirmEditStudentInfo: '确定编辑学生的信息',
    confirmEditStudentInfoTxt: '你确定编辑该学生的信息？',
  },

  questionTxt: {
    pinQuestion: 'Ghim câu hỏi',
    questionBank: '题库',
    topicContent: '题目内容',
    difficulty: '难度',
    special: '专题',
    theme: '主题',
    knowledgePoints: '知识点',
    analysis: '解析',
    venogram: '脉络',
    content: '内容',
    infof: '信息',
    commonlyInfo: '一般信息',
    detailedInfo: '详细信息',
    questionType: '题型',
    syncTime: '同步时间',
    fSchoolQuestionBank: 'Fschool题库',
    schoolQuestionBank: '学校题库',
    manualCreate: '手工创建',
    wordImport: 'Word批量导入',
    choose: '从学校题库/Fschool题库挑选',
    createQuestionTitle: '创建题目',
    uploadFile: '上传文件',
    downloadFile: '下载文件',
    batchImportQuestion: '批量导入试题',
    knowledgeField: '知识字段',
    addKnowledgeField: '添加知识字段',
    editQuestionTitle: '编辑题目',
    updateQuestionTitle: '更新题目',
    preview: '预览',
    conserveAndEditOther: '保存 & 编辑其他',
    questionTitle: '题目',
    addAnswer: '添加答案',
    rightKey: '正确答案',
    addBlankBtn: '添加填空框',
    problem: '问题',
    delQuestion: '删除题目?',
    delQuestionTxt:
      '您确定要从试题库中删除此题目吗？试题将在题库中及试卷中被删除，试题在被分发的作业将不会被删除。',
    fileParsing: '文件解析中...',
    subjectType: '题型',
    success_num_front: '您已添加',
    success_num_after: '个题目',
    addQuestion: '添加试题',
    difficultyAll: '全部难度',
    upToTenOptions: '最多可以有十个选项哦~~~！',
    personalQuestionBank: '个人题库',
    deleteAnswerTxt: '确定要删除该选项吗？',
    notInOtherGrade: 'Câu hỏi này hiện tại không được gán với khối nào khác.',
    inOtherGrade: 'Câu hỏi này cũng được gán với khối.',
    view: 'Xem',
    addedQuestion: 'Đã Thêm',
  },

  import: {
    importTips_1: '分析文档后，相关公式将转换为图像',
    importTips_2: '问题输入规则:',
    importTips_3: '第1部分: 是一般题干 (若有)仅在 “一项多题”类型',
    importTips_4:
      '第2部分: 是题目的题干 (对于 “一项多题”类型是每个小题目的题干)',
    importTips_5:
      '第3部分：这是答案的内容。 选项的顶部必须以 [A, -J,] 开头，并且是顺序地和连续的。 选项可以插入图片、公式等, v.v',
    importTips_6:
      '第4部分: 是选项题的正确答案 ,答案必须是次序(AJ)答案必须是每个选项的编号或紧接在答案第一部分之后的答案 .对于填空题，每个填空题的答案前必须有 {n}。 对应的n是空白对应的数字',
    importTips_7:
      '第5部分: 作为解释问题的内容，分析应该是在“解析”第一部分之后的文本内容的分析；',
    importTips_8:
      '第6部分: 作为题目的题型，题型必须以“题型：”开头，题型分为5类 (单选题, 多选题, 笔试题, 填空题 và 一项多题).',
    importTips_9:
      '第7部分: 试卷的难度 , 难度要以 "难度:"开始, 难度分为4类(认知, 精通, 运用，高级运用).',
    importTips_10:
      '注意:试题中的选项必须大于等于2个选项，如果只有2个选项为对或错，如果只有一个答案选项且多于2个选项，则为单选。。 有多个答案和2个以上的选择，这是一个选择题。',
    importTips_11: '插入图片区域前后必须有字符，可以是文字，也可以是标点符号。',
  },

  examTxt: {
    beingUpdated: 'Tính năng này đang được cập nhật',
    testPaper: '试卷题库',
    testPaperBank: '试卷题库',
    testPaperName: 'Tên đề thi',
    inputTestPaperName: '请输入试卷名称',
    topicQuantity: '题量',
    time_min: '时间（分钟）',
    topicType: '题类',
    release: '发布',
    place: '地点',
    conversionTestPaper: '转级试卷',
    courseTestPaper: '按照课程的试卷',
    contestTestPaper: '竞赛试卷，奥林匹克试卷等',
    createExamTxt: 'Tạo đề thi',
    createOtherExamTxt: 'Tạo đề thi khác',
    mixExam: 'ĐẢO ĐỀ',
    mixQuestions: 'Đảo thứ tự câu',
    mixAnswers: 'Đảo thứ tự đáp án',
    showAnswer: '展示答案',
    order: '次序',
    answer: '答案',
    answerContent: '答案内容',
    uploadAnswer: '笔试题-上传答案',
    rightAnswer: '正确答案',
    explain: '解释',
    hotPaper: '热门试卷',
    newPaper: '新试卷',
    addPaperBtn: '创建试卷',
    delPaper: '删除试卷',
    delPaperTxt: '试卷将从题库中永久删除',
    manualAddBtn: '从题库创建试卷',
    manualAddTxt: '从Fschool/学校/老师题库手工创建作业',
    structureAddBtn: '按试卷结构与知识点分布创建作业',
    structureAddTxt:
      ' 从Fschool/学校/老师的题目和试卷结构以及知识点分布来创建作业',
    computerAddBtn: '从电脑选择',
    computerAddTxt: '从电脑选择Word/PDF来创建作业',
    meansAddBtn: '从学习资料选择',
    meansAddTxt: '从Fschool/学校/老师的题库创建作业 ',
    fromStructureCreate: '从试卷结构创建',
    fromComputerCreate: '从电脑创建',
    paper: '试卷',
    excercise: '锻炼',
    updatePaper: '修改试卷',
    testPaperName: '试卷名称',
    testDescribe: '试卷描述',
    inputTestDescribe: '请输入试卷描述',
    inputTestDescribeExcercise: '输入练习的描述',
    timeMin: '时间（分钟）',
    verTxt: {
      testPaperName: '请输入试卷名称',
      timeMin: '请输入时间（分钟）',
      inputInt: '请输入正整数',
    },
    minute: '分',
    cancelChoice: '取消选择',
    conservePaper: '保存试卷',
    addQuestionBtn: '添加题目',
    scoring: '计分',
    scoringSystem: '分制',
    totalScoreTxt: '总分',
    questionNum: '题量',
    proportion: '比例',
    statistics: '统计',
    score: '分数',
    move: '移动',
    topic: '题',
    dragOrClickUpload: '拖放文件或点击上传',
    createPaperStructureAndKnowledge: '创建试卷结构与知识点分布',
    time: '时间',
    min: '分钟',
    lookAndCheck: '看查',
    choice: '选择',
    preserveCheckTxt: '保存在题库',
    selectRightAnswer: '选择正确答案',
    inputAnswerTxt: '输入答案',
    setBlankAnswer: '设置填空题的答案',
    setBlankAnswerTips:
      '* 输入问题的正确答案。点击 Enter 以添加答案。如果学生像以下情况下回答，则系统将其视为正确。',
    blankFrame: '填空框',
    fromTestPaperBankCreate: '从题库创建',
    createFromQuestionBankTxt: 'Tạo từ ngân hàng câu hỏi',
    topicRemoved: '题目已移除',
    topicAdded: '题目已添加',
    uncheckQuestion: 'Đã bỏ chọn câu hỏi',
    dragUploadText: '接受文件格式(Doc, PDF) -请不要> 100 MB',
    testPaperTxt: '试卷',
    addToQuestionBank: '添加到题库',
    testPaperAll: '全部试卷',
    personalTestPaper: '个人试卷',
    schoolTestPaper: '学校试卷',
    manualAddBtnTask: '从题库创建作业',
    manualAddTxtTask: '从Fschool/学校/老师题库手工创建作业',
    structureAddBtnTask: '按作业结构与知识点分布创建作业',
    structureAddTxtTask:
      ' 从Fschool/学校/老师的题目和作业结构以及知识点分布来创建作业',
  },

  structure: {
    paperStructure: '试卷结构',
    createPaperStructure: '创建试卷结构',
    source: '来源',
    inputStructureName: '请输入结构名称',
    synchronous: '同步',
    local: '本平台',
    continue: '继续',
    paperStructureName: '试卷结构名称',
    paperStructureDescribe: '试卷结构描述',
    verTxt: {
      inputStructureName: '请输试卷结构名称',
    },
    backFirstStep: '返回第1步骤',
    variousSubjectsAverage: '各科平均分',
    totalQuestionsNum: '题目总数',
    totalScore: '总分数',
    total: '总',
    addPaperStructure: '添加试卷结构',
    conserveStructure: '保存试卷结构',
    delStructure: '删除试卷结构?',
    delStructureTxt: '试卷结构将永远被删除. 用该试卷结构创建试卷将不会收影响',
    describe: '描述',
    questionBankFschool: '题库 Fschool',
    complete: '完成',
    addToBtn: '添加',
    addedBtn: '已添加',
    schoolTxt: '学校',
    fillCorrectlyAverageScore: '请正确填写平均分',
    selectQuestion: '选择试题',
  },

  practise: {
    exercises: '练习题',
    addTestPaper: '添加试卷',
    delPractise: '删除练习题?',
    delPractiseTxt: '你确定删除吗? （练习题将隐藏在学生客户部分）',
    paperLibrary: '试卷库',
    allTxt: '全部',
    published: '已发布',
    unpublished: '未发布',
    allGradeTxt: '全部年级',
    isPublish: '已出版',
    notPublish: '未出版',
    savePractice: 'Lưu bài tập',
  },

  print: {
    common: '共',
    page: '页',
    questionTime: '做题时间',
    admissionNum: '准考证号',
    multipleQuestion: '多选题',
    fileGenerating: '文件生成中',
    shortQuestion: '简答题',
  },

  synthesize: {
    titleTxt: ' 综合题干',
  },

  specialStructure: {
    variousSubjectsAverage1: '各科平均分',
    variousSubjectsAverage2: '',
    totalQuestionsNum1: '题目总数',
    totalQuestionsNum2: '',
    totalScore1: '总分数',
    totalScore2: '',
  },

  updateQstTips: {
    setSingleCont: '请设置单选题内容',
    inputOptCont: '请输入选项内容',
    setSingleAnswer: '请设置单选题答案',
    setMultipleCont: '请设置多选题内容',
    setMultipleAnswer: '请设置2个或2个以上答案',
    setBlanksCont: '请设置填空题内容',
    inputAnswerCont: '请输入答案内容',
    blanksErrTips: '答案与输入框数量不一致',
    confirmDeleteOpt: '确定要删除该选项吗？',
    DeleteTheAnswer: 'Bạn chắc chắn muốn xóa câu hỏi này không?',
    setSyntCont: '请设置综合题内容',
    front_topic_txt: '问题序号',
    pleaseSetSectionTxt: '请设置',
    topic_problemTxt: '问题',
    topic_analysisTxt: '解析',
    topic_optionTxt: '选项',
    topic_answerTxt: '答案',
    inputAnalysis: 'Hãy nhập nội dung giải thích',
    topic_knowledge: 'kiến thức cho câu hỏi',
    pleaseSetSectionKnowlEdge: 'Chưa chọn mạch',
  },

  task: {
    mixExamTotal: 'Số đề đảo',
    taskTxt: '作业',
    draft: '草稿',
    published: '已发布',
    folder: '文件夹',
    folderName: '文件夹名称',
    startDateTxt: '开始日期',
    endDateTxt: '结束日期',
    lastWeek: '近七天',
    LastMonth: '近一月',
    todayTxt: '今天',
    sort: '排序',
    newest: '最新',
    oldest: '最旧',
    createNewFolder: '创建新文件夹',
    updateFolder: '修改文件夹',
    changeName: '更换名称',
    deleteFolder: '删除文件夹',
    prompt: '提示',
    deleteFolderPrompt: '在文件夹中的所有作业，资料将保存到”全部”文件夹',
    createTask: '创建作业',
    createPaper: '创建考试问题',
    taskName: '作业名称',
    taskDescribe: '作业描述',
    verTxt: {
      taskName: '请输入作业名称',
    },
    distributeTask: '分发作业',
    saveDraft: '保存草稿',
    saveAndPublishLater: 'Lưu & giao sau',
    taskToClass: '给此班级分发作业',
    canQuestionTime: '允许做题时间',
    viewAnswers: '查看答案',
    answersOrder: '颠倒答案顺序',
    takeInteger: '取整分数',
    atAnyTimeQuestions: '学生可以随时做题',
    timingMakeQuestions: '学生只能在一定时间内做题',
    onCompletionLook: '当学生完成作业时',
    afterTimeoutLook: '当超过允许做题时间',
    notAllowLook: '不让学生看到答案',
    yesText: '是',
    noText: '否',
    reachTxt: '到',
    submittedTaskStudent: '已提交作业的学生',
    endTime: '截止时间',
    unlimitedTxt: '没有限制',
    actionTxt: '行动',
    moveFolder: '移动文件夹',
    shareTxt: '分享',
    personalTxt: '个人',
    updateTask: '修改作业',
    updateTaskCont1: '作业已分发给学生',
    updateTaskCont2: '你只能修改作业信息。',
    updateTaskCont3: '如果想修改试题或设置，请在备份版本进行修改！',
    createBackupsVersion: '创建备份版本',
    deleteTask: '删除作业',
    deleteDraftTask: '你确认想删除该作业 ???',
    deletePublishedTask:
      '您确定要删除此作业吗？？？ （学生的作业列表中的该绩和作业将被删除）',
    selectFolderToMigrate: '选择迁移的文件夹',
    clickCopyTxt: '点击“复制”分享链接',
    shareDescribe: '描述分享内容',
    printTxt: '打印',
    completedTask: '已完成作业',
    copy: '复制',
    copySuccessTxt: '复制成功',
    publishedTask: '已发布作业',
    taskList: '作业列表',
    alreadyDo: '已做',
    notDo: '未做',
    taskContent: '作业内容',
    releaseTime: '发布时间',
    personalInfoDescribeTxt: '用户想编辑信息时要向学校管理员通知',
    stemTxt: '题干',
    makeQuestionList: '做题列表',
    taskInfo: '作业信息',
    titleTxt: '标题',
    subPaperTime: '交卷时间',
    subPaperNum: '已交卷的学生总数',
    noTxt: '没有',
    againDistributeTask: '再次分发作业',
    allTxt: '全部',
    scoredTxt: '已打分',
    notScoredTxt: '未打分',
    notSubPaper: '未交卷',
    scoreTxt: '打分',
    studentAnswer: '学生的答案',
    paperRightAnswer: '试卷的正确答案',
    previewTxt_1: '第',
    previewTxt_2: '题的答案',
    mostTxt: '最多',
    modifyScore: '修改分数',
    resultTxt: '结果',
    historyTxt: '历史',
    scoreEachTopic: '各题目打分',
    writtenExamQst: '笔试题',
    historyInfo: '历史信息',
    startTime: '开始时间',
    submitTime: '提交时间',
    submitStartTime: 'Bắt đầu làm bài',
    completeScored: '完成打分',
    completeScoredTxt: '打分完之后则在学生端显示作业的结果. 你确定想要完成吗? ',
    continueScored: '继续打分',
    modifyFraction: '修改分数',
    teacherComment: '老师的评价',
    modifyComment: '修改评价',
    continueNoStudentTxt:
      '你已完成为已交卷的学生打分. 你想返回学生做题列表界面吗？',
    noAnswer: '此题未答',
    hasATimeoutTxt: 'Đã quá hạn',
    selectNextStudentScored: '选择下一个要评分的学生',
    seek: '寻找',
    classStudent: '班的学生',
    distributeNow: '立即分发',
    deleteQuestion: '删除试题?',
    deleteQuestionTxt: '你确认想删除该试题?',
    distribution: '分配',
    distributionTask: '分配作业',
    searchTaskName: '搜索作业名称',
    taskNameRetrieval: '按作业名称搜索',
    noneAtPresent: '暂无',
    markScoreFront: '第',
    markScoreAfter: '题未打分',
    markExamPaper: 'Số học sinh đã chấm',
  },

  publishJob: {
    zuoye: '作业',
    kaojuan: '试卷',
  },

  notify: {
    order: '次序',
    notifyTltle: '通知标题',
    createPeople: '创建人',
    notifyTime: '通知时间',
    deleteNotify: '删除通知',
    deleteNotifyTxt: '你确认想删除该通知吗?',
    createNotify: '创建通知',
    notifyContent: '通知内容',
    getNotifyObj: '收通知对象',
    teacherCode: '老师编码',
    inputTeacherCodeOrName: '老师编码或姓名',
    selected: '已选',
    selectedTeacher: '老师',
    selectedStudent: '学生',
    surveyNotify: '概况通知',
    systemNotify: '系统通知',
    ureadTxt: '未读',
    newTxt: '新',
    beforeTxt: '之前',
    tabReadTxt: '标记为已读',
    tabAllRead: '标记全部为已读',
    removeNotify: '移除改通知',
    completeAfterNotify: '完成创建通知后立刻推送',
    customTime: '自定义时间',
    inputName: '输入名称',
    notifiedTxt: '已通知',
    notNotifiedTxt: '未通知',

    monthFrontTxt: '个月前',
    weekFrontTxt: '周前',
    dayFrontTxt: '天前',
    hourFrontTxt: '个小时前',
    minFrontTxt: '分钟前',
    justTxt: '刚刚',

    startTxt: '开始',
    endTxt: '结束',
    addPlaceholderTxt: '配置发送消息的时间',
    mustCreateNextSchoolYear: 'Bạn phải tạo năm học mới trước',
  },

  statistical: {
    statisticalTxt: '统计',
    wholeSystemStatistical: '全系统统计',
    bySchoolStatistical: '按学校统计',
    overviewStatistics: '概况统计',
    endTime: '截止时间',
    primarySchool: '小学',
    middleSchool: '中学',
    highSchool: '高中',
    total: '总共',
    accountStatisticsByTime: '按时间统计账号',
    day: '日',
    month: '月',
    questionPpaperStatistical: '统计试卷和试题',
    keyWordStatistical: '关键词统计',
    backList: '返回列表',
    accountStatistical: '账户统计',
    statisticsOfStudentsByStatus: '按状态统计学生账号',
    accountTotal: '账号总数',
    activeUser: '活跃用户',
    inactiveUser: '不活跃用户',
    keyWord: '关键词',
    frequency: '次数',
    wiewList: '看查列表',
    getSeveralTimes: '获取数次',
  },
}

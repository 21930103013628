<template>
  <el-config-provider :locale="locale">
    <div>
      <router-view v-if="refreshState" />
    </div>
  </el-config-provider>
</template>
<script>
import { ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { ElConfigProvider } from 'element-plus'
import zh_cn from 'element-plus/lib/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'
import vi from 'element-plus/lib/locale/lang/vi'
import { initWebPush } from './utils/app.utils'
export default {
  setup() {
    // sessionStorage.setItem('useLang', 'zh_cn')
    sessionStorage.setItem('useLang', 'vi')
    const store = useStore()
    let locale = ref(vi)
    let refreshState = ref(true)

    const setElementLang = () => {
      // 设置 Element 语言 Setting the Element language
      switch (store.state.lang.defaultLang) {
        case 'en':
          locale.value = en
          break
        case 'zh_cn':
          locale.value = zh_cn
          break
        case 'vi':
          locale.value = vi
          break
        default:
          locale.value = vi // 默认越南语 Default Vietnamese
          break
      }
    }

    setElementLang()
    onMounted(() => {
      if (
        !['/login', '/'].includes(window.location.pathname) &&
        process.env.NODE_ENV === 'production'
      ) {
        initWebPush()
      }
    })

    // * remove syId cause of get ancient syId issues
    localStorage.removeItem('syIdSchoolYearCurrent')

    watch(
      () => store.state.lang.defaultLang,
      () => {
        setElementLang()
        refreshState.value = false
        setTimeout(() => {
          refreshState.value = true
        }, 10)
      },
    )

    return {
      locale,
      refreshState,
    }
  },
  components: {
    ElConfigProvider,
  },
}
</script>
<style>
#app {
}
</style>

export default {
  methods: {
    getFileUrl(fileUrl) {
      return fileUrl
        ? fileUrl.includes('http')
          ? fileUrl
          : this.$resourceUrl + fileUrl
        : ''
    },
  },
}

import { ElNotification } from 'element-plus'
import { ElMessage } from 'element-plus'
import { ElLoading } from 'element-plus'
import router from '@/router'
import store from '../store'
let loading = ''
class Params {
  setCookie(cname, cvalue, exdays = 1) {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = 'expires=' + d.toUTCString()
    document.cookie =
      cname + '=' + cvalue + ';' + expires + ';path=/;SameSite=Lax'
  }

  getCookie(cname) {
    let name = cname + '='
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;path=/;SameSite=Lax'
  }

  notifyMsg(msg, type, title, useHtml = false) {
    title = title || ''
    ElNotification({
      title:
        title != ''
          ? title
          : type === 'suc'
            ? '成功'
            : type === 'err'
              ? '错误'
              : type === 'war'
                ? '警告'
                : '消息',
      message: msg,
      type:
        type === 'suc'
          ? 'success'
          : type === 'err'
            ? 'error'
            : type === 'war'
              ? 'warning'
              : 'info',
      duration: 5000,
      dangerouslyUseHTMLString: useHtml,
    })
  }

  promptMsg(msg, type) {
    //显示错误 成功 警告提示 The error success warning is displayed
    ElMessage({
      message: msg,
      type:
        type === 'suc'
          ? 'success'
          : type === 'err'
            ? 'error'
            : type === 'war'
              ? 'warning'
              : 'info',
      showClose: true,
      duration: 5000,
    })
  }

  startLoading(text = '', lock = true, background = 'rgba(0,0,0,0.7)') {
    //开始加载动画 Start loading the animation
    const options = { lock, text, background: background }
    loading = ElLoading.service(options)
  }
  endLoading() {
    //结束加载动画 End loading animation
    loading.close()
  }

  clearPersonalStorageData() {
    store?.commit('setSchoolInfo', null)
    store?.commit('resetState')
    window.localStorage.removeItem('adminInfo')
    this.eraseCookie('accessToken')
    window.localStorage.removeItem('addedQuestionCount')
    window.localStorage.removeItem('menuList')
  }

  Axios({
    url = '',
    method = 'post',
    transformRequest,
    transformResponse,
    onUploadProgress,
    headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data = {},
    timeout = 10000,
    withCredentials = false,
    responseType = 'json',
    loadingStatus = false,
    hiddenNotify = false,
  }) {
    //请求拦截 Request to intercept
    axios.interceptors.request.use((config) => {
      if (loadingStatus == true) {
        this.startLoading()
      }
      return config
    })
    return axios({
      url,
      method,
      transformRequest,
      transformResponse,
      onUploadProgress,
      headers,
      [method === 'get' ? 'params' : 'data']: data,
      timeout,
      withCredentials,
      responseType,
    })
      .then((res) => {
        if (loadingStatus == true) {
          this.endLoading()
        }
        if (res.data.ok) {
          return res.data
        } else {
          hiddenNotify =
            hiddenNotify ||
            (res.data.entity && url.startsWith(`/admin/auth/cus/createUser?`))
          if (res.data.code == 'NOT_LOGIN') {
            this.clearPersonalStorageData()
            router.push('/')
          } else if (!hiddenNotify) {
            this.promptMsg(res.data.message, 'war')
            return res.data
          }
          return res.data
        }
      })
      .catch((err) => {
        console.error(`错误提示：${err}`)
      })
  }

  AxiosOfOutside({
    //用这个 axios
    url = '',
    method = 'get',
    transformRequest,
    transformResponse,
    onUploadProgress,
    headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data = {},
    timeout = 10000,
    withCredentials = false,
    responseType = 'json',
    loadingStatus = false,
    source = '96ke',
  }) {
    //请求拦截 Request to intercept
    axios.interceptors.request.use((config) => {
      if (loadingStatus == true) {
        this.startLoading()
      }
      return config
    })
    return axios({
      url,
      method,
      transformRequest,
      transformResponse,
      onUploadProgress,
      headers,
      [method === 'get' ? 'params' : 'data']: data,
      timeout,
      withCredentials,
      responseType,
    })
      .then((res) => {
        if (loadingStatus == true) {
          this.endLoading()
        }
        if (source == '96ke') {
          if (res.data.error_info.errno === 1) {
            return res.data
          } else {
            this.promptMsg(res.data.error_info.error, 'war')
            return res.data
          }
        }
      })
      .catch((err) => {
        console.log(`错误提示：${err}`)
      })
  }

  getStreamFile({
    // 获取流文件 Getting a stream file
    url = '',
    method = 'post',
    transformRequest,
    transformResponse,
    onUploadProgress,
    headers = { 'Content-Type': 'application/x-www-form-urlencoded' },
    data = {},
    timeout = 1000000,
    withCredentials = false,
    responseType = 'blob',
    loadingStatus = false,
  }) {
    //请求拦截 Request to intercept
    axios.interceptors.request.use((config) => {
      if (loadingStatus == true) {
        this.startLoading()
      }
      return config
    })
    return axios({
      url,
      method,
      transformRequest,
      transformResponse,
      onUploadProgress,
      headers,
      [method === 'get' ? 'params' : 'data']: data,
      timeout,
      withCredentials,
      responseType,
    })
      .then((res) => {
        if (res.headers['content-disposition'] != undefined) {
          let fileName = decodeURI(
            res.headers['content-disposition'].split('=')[1],
          )
          if (res.data) {
            let data = {
              blobFile: res.data,
              fileName,
            }
            return data
          } else {
            return false
          }
        } else {
          return false
        }
      })
      .catch((err) => {
        console.log(`错误提示：${err}`)
      })
  }

  getTimeType(tiem, type) {
    let date = new Date(tiem)
    let yyyy = date.getFullYear()
    let MM = date.getMonth() + 1
    let dd = date.getDate()
    let hh = date.getHours() //获取当前小时s Obtaining the Current Hour
    let mm = date.getMinutes() //获取当前分钟 Obtaining the Current Minute
    let ss = date.getSeconds() //获取当前秒 Get the current second

    if (type == 'yyyy-MM-dd hh:mm:ss') {
      return `${yyyy}-${MM < 10 ? '0' + MM : MM}-${dd < 10 ? '0' + dd : dd} ${hh < 10 ? '0' + hh : hh
        }:${mm < 10 ? '0' + mm : mm}:${ss < 10 ? '0' + ss : ss}`
    } else if (type == 'yyyy-MM-dd 00:00:00') {
      return `${yyyy}-${MM}-${dd} 00:00:00`
    } else if (type == 'hh:mm MM-dd-yyyy') {
      return `${hh < 10 ? '0' + hh : hh}:${mm < 10 ? '0' + mm : mm} ${MM < 10 ? '0' + MM : MM
        }/${dd < 10 ? '0' + dd : dd}/${yyyy}`
    } else if (type == 'hh:mm:ss dd-MM-yyyy') {
      return `${hh < 10 ? '0' + hh : hh}:${mm < 10 ? '0' + mm : mm}:${ss < 10 ? '0' + ss : ss
        } ${dd < 10 ? '0' + dd : dd}/${MM < 10 ? '0' + MM : MM}/${yyyy}`
    } else if (type == 'dd-MM-yyyy') {
      return `${dd < 10 ? '0' + dd : dd}/${MM < 10 ? '0' + MM : MM}/${yyyy}`
    } else if (type == 'yyyy-MM-dd') {
      return `${yyyy}-${MM < 10 ? '0' + MM : MM}-${dd < 10 ? '0' + dd : dd}`
    } else {
      return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`
    }
  }

  longNumberOperation(a, b, type) {
    a = a + ''
    b = b + ''
    let result
    if (type) {
      result = this.add(a, b)
    } else {
      result = this.sub(a, b)
    }
    return result
  }

  add(a, b) {
    //取两个数字的最大长度 Take the maximum length of two digits
    let maxLength = Math.max(a.length, b.length)
    //用0去补齐长度 I'm going to fill out the length with 0
    a = a.padStart(maxLength, 0)
    b = b.padStart(maxLength, 0)
    //定义加法过程中需要用到的变量 Define the variables that you're going to use in addition
    let t = 0
    let f = 0 //"进位" "Carry"
    let sum = ''
    for (let i = maxLength - 1; i >= 0; i--) {
      t = parseInt(a[i]) + parseInt(b[i]) + f
      f = Math.floor(t / 10)
      sum = (t % 10) + sum
    }
    if (f == 1) {
      sum = '1' + sum
    }
    return sum
  }

  sub(num1, num2) {
    if (num1 === num2) return '0'

    function lt(num1, num2) {
      if (num1.length < num2.length) {
        return true
      } else if (num1.length === num2.length) {
        return num1 < num2
      } else {
        return false
      }
    }
    let isMinus = false
    if (lt(num1, num2)) {
      ;[num1, num2] = [num2, num1]
      isMinus = true
    }

    let len = Math.max(num1.length, num2.length)
    num1 = num1.padStart(len, 0)
    num2 = num2.padStart(len, 0)

    let flag = 0,
      result = '',
      temp
    for (let i = len - 1; i >= 0; i--) {
      temp = parseInt(num1[i]) - flag - parseInt(num2[i])
      if (temp < 0) {
        result = 10 + temp + result
        flag = 1
      } else {
        result = temp + result
        flag = 0
      }
    }
    result = (isMinus ? '-' : '') + result.replace(/^0+/, '')
    return result
  }

  removePropertyOfNull(obj) {
    Object.keys(obj).forEach((item) => {
      if (obj[item] == null || obj[item] == undefined) {
        delete obj[item]
      }
    })
    return obj
  }

  secondToTime(second) {
    var time
    if (second > -1) {
      var hour = Math.floor(second / 3600)
      var min = Math.floor(second / 60) % 60
      var sec = second % 60
      if (hour < 10) {
        time = '0' + hour + ':'
      } else {
        time = hour + ':'
      }
      if (min < 10) {
        time += '0'
      }
      time += min + ':'
      if (sec < 10) {
        time += '0'
      }
      time += sec
    }

    return time
  }

  delHtmlTag(str) {
    return str.replace(/<[^>]+>/g, '')
  }

  getDevice() {
    // 看设备需要多大像素 See how many pixels the device needs
    if (window.devicePixelRatio && window.devicePixelRatio > 1) {
      return window.devicePixelRatio
    }
    return 1
  }

  getFiles(data) {
    // 将图片转成file格式 Convert the image to file format
    let arr = data.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = window.atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], 'posters.jpg', {
      type: mime,
    })
  }

  getPdfFiles(data, fileName) {
    // 将Pdf base64转成file格式 Convert Pdf base64 to file format
    let arr = data.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = window.atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], fileName, {
      type: mime,
    })
  }

  viewShowFormula(vueInstance, element) {
    vueInstance.$nextTick(() =>
      window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, element]),
    )
  }

  getCharCount(str, char) {
    // 获取字符串中 指定 字符数量 Gets the specified number of characters in a string
    let regex = new RegExp(char, 'g')
    let result = str.match(regex)
    let count = !result ? 0 : result.length
    return count
  }

  encryptionCode(str) {
    //加密字符串 Encrypted string
    var key = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var keyl = key.length
    var a = key.split('')
    var s = '',
      b,
      b1,
      b2,
      b3
    for (var i = 0; i < str.length; i++) {
      b = str.charCodeAt(i)
      b1 = b % keyl
      b = (b - b1) / keyl
      b2 = b % keyl
      b = (b - b2) / keyl
      b3 = b % keyl
      s += a[b3] + a[b2] + a[b1]
    }
    return s
  }

  // delete white space blank answer of question
  // modify blank answers, =>> delete white space
  modifyBlankAnswerQuestion(listQuestions) {
    listQuestions.forEach((item) => {
      const contentClone = JSON.parse(item.content)
      if (contentClone?.blanksOptList.length) {
        contentClone.blanksOptList.map((item) => {
          item.answer = item.answer
            .split('|')
            .map((item) => item.trim())
            .filter((item) => item)
            .join('|')
          return item
        })

        item.content = JSON.stringify(contentClone)
      }

      if (item?.questionContent?.blanksOptList.length) {
        item.questionContent.blanksOptList.map((item) => {
          item.answer = item.answer
            .split('|')
            .map((item) => item.trim())
            .filter((item) => item)
            .join('|')
          return item
        })
      }
    })
  }
}
export default new Params()

export default {
  state: {
    classUseData: sessionStorage.getItem('classData')
      ? JSON.parse(sessionStorage.getItem('classData'))
      : '',
    classSubject: {},
  },

  mutations: {
    refreshUseData(state, data) {
      state.classUseData = data
    },
    setClassSubject(state, data) {
      state.classSubject = data
    },
  },
}

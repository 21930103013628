const getClassListByAdmin = async (pageOpstion, paramsData) => {
  return await $.Axios({
    url:
      '/admin/schoolClass/findSchoolClassListPage?' +
      encrypt.encryptGetSign(pageOpstion, paramsData),
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(paramsData),
    transformRequest(data) {
      return data
    },
  })
}

const findSchoolClassLevelUpList = async (paramsData) => {
  return await $.Axios({
    url:
      'admin/schoolClass/findSchoolClassLevelUpList?' +
      encrypt.encryptUrlData(paramsData),
  })
}

const levelUp = async (paramsData) => {
  return await $.Axios({
    url: 'admin/schoolClass/levelup?' + encrypt.encryptGetSign({}, paramsData),
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(paramsData),
    transformRequest(data) {
      return data
    },
  })
}

const findClassSubjectListByTeacher = async (paramsData) => {
  return await $.Axios({
    url: `/admin/sct/findClassSubjectListByTeacher?${encrypt.encryptUrlData(
      paramsData,
    )}`,
  })
}

const findSchoolClassById = async (scId) => {
  const res = await $.Axios({
    url:
      '/admin/schoolClass/findSchoolClassById?' +
      encrypt.encryptUrlData({ scId }),
  })
  if (res.ok) {
    return res.entity
  }
}

const findClassListByLevel = async (level) => {
  try {
    let { entityList } = await $.Axios({
      url:
        '/admin/schoolClass/findSchoolLevelClassGrade?' +
        encrypt.encryptUrlData({ level }),
    })
    return entityList
  } catch (error) {
    console.log('findClassListByLevel error', error)
    return []
  }
}

const leaveOrTransferSchool = async (params) => {
  try {
    return $.Axios({
      url: `/admin/schoolClass/leaveOrTransferSchool?${encrypt.encryptUrlData(
        params,
      )}`,
    })
  } catch (error) {
    console.log('leaveOrTransferSchool error', error)
  }
}

const changeClassForStudents = async (payload) => {
  try {
    return $.Axios({
      url: `/admin/schoolClass/changeClassForStudents?${encrypt.encryptGetSign(
        {},
        payload,
      )}`,
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify(payload),
      transformRequest(data) {
        return data
      },
    })
  } catch (error) {
    console.log('changeClassForStudents', error)
  }
}

export default {
  findSchoolClassLevelUpList,
  levelUp,
  getClassListByAdmin,
  findClassSubjectListByTeacher,
  findSchoolClassById,
  findClassListByLevel,
  leaveOrTransferSchool,
  changeClassForStudents,
}

/* eslint-disable no-undef */
const branchService = {
  findBranchList: async (paramsData = {}) => {
    try {
      const res = await $.Axios({
        url:
          '/admin/branch/findBranchList?' +
          encrypt.encryptGetSign({}, paramsData),
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(paramsData),
        transformRequest(data) {
          return data
        },
      })
      return res.entityList || []
    } catch (error) {
      console.log(error)
      return []
    }
  },
  findClassSubjectListByClass: async (params) => {
    try {
      const { entityList = [] } = await $.Axios({
        url:
          'admin/sct/findClassSubjectListByClass?' +
          encrypt.encryptUrlData(params),
      })
      const listBranchById = {}
      entityList.forEach(({ branchList = [] }) => {
        branchList.forEach((branch) => {
          if (!listBranchById[branch.branchId])
            listBranchById[branch.branchId] = branch
        })
      })

      return Object.keys(listBranchById).map(
        (branchId) => listBranchById[branchId],
      )
    } catch (error) {
      console.log('findClassSubjectListByClass', error)
      return []
    }
  },
}

export default branchService

import { createStore } from 'vuex'
import menu from './modules/menu'
import lang from './modules/lang'
import other from './modules/other'
import classUse from './modules/classUse'
import question from './modules/question'
import schoolService from '../services/school.service'

const subjectMap = {
  GIAO_DUC_KINH_TE_VA_PHAP_LUAT: import(
    '@/assets/image/subject/GIAO_DUC_KINH_TE_VA_PHAP_LUAT.png'
  ),
  GIAO_DUC_CONG_DAN: import('@/assets/image/subject/GIAO_DUC_CONG_DAN.png'),
  DIA_LY: import('@/assets/image/subject/DIA_LY.png'),
  HOA_HOC: import('@/assets/image/subject/HOA_HOC.png'),
  KHOA_HOC_TU_NHIEN: import('@/assets/image/subject/KHOA_HOC_TU_NHIEN.png'),
  LICH_SU: import('@/assets/image/subject/LICH_SU.png'),
  'LICH_SU_-_DIA_LY': import('@/assets/image/subject/LICH_SU_-_DIA_LY.png'),
  NGU_VAN: import('@/assets/image/subject/NGU_VAN.png'),
  SINH_HOC: import('@/assets/image/subject/SINH_HOC.png'),
  TIENG_ANH: import('@/assets/image/subject/TIENG_ANH.png'),
  TIENG_VIET: import('@/assets/image/subject/TIENG_VIET.png'),
  TOAN: import('@/assets/image/subject/TOAN.png'),
  VAT_LY: import('@/assets/image/subject/VAT_LY.png'),
  Default: import('@/assets/image/subject/Default.png'),
}

const subjectMapColor = {
  DIA_LY: '#EFFAFF',
  HOA_HOC: '#FCFFE7',
  KHOA_HOC_TU_NHIEN: '#FEF1FF',
  LICH_SU: '#F4E7ED',
  'LICH_SU_-_DIA_LY': '#EFF2FF',
  NGU_VAN: '#EFD6D2',
  SINH_HOC: '#FFF6F1',
  TIENG_ANH: '#DAEFFB',
  TIENG_VIET: '#D7F1DB',
  TOAN: '#ECFFF8',
  VAT_LY: '#ECEBFF',
  GIAO_DUC_CONG_DAN: '#FAD1B7',
  GIAO_DUC_KINH_TE_VA_PHAP_LUAT: '#87BEFF',
  DANH_GIA_NANG_LUC: '#B8FFD9 ',
  Default: '#FFDFB8',
}

export default createStore({
  state: {
    subjectMap,
    subjectMapColor,
    schoolInfo: null,
  },
  mutations: {
    setSchoolInfo(state, schoolInfo) {
      state.schoolInfo = schoolInfo
    }
  },
  actions: {
    async findSchoolById({ commit }) {
      try {
        const schoolId = localStorage.getItem('adminInfo') && JSON.parse(localStorage.getItem('adminInfo'))?.schoolId
        const schoolRes = await schoolService.findSchoolById(schoolId)
        if (schoolRes.ok) {
          commit('setSchoolInfo', schoolRes.entity)
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  modules: {
    menu,
    lang,
    other,
    classUse,
    question,
  },
})

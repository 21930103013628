/* eslint-disable no-undef */
import { validateBlankQuestion } from '@/utils/validateQuestion'
// HELPER FUNC
const validBlank = (question) => {
  if (question?.type === 'blanks') {
    return validateBlankQuestion(question)
  }
  return true
}
//
const getQuestionTypeList = async () => {
  //获取试题类型 Get question type
  let res = await $.Axios({
    url: '/admin/question/findQuestionTypeList?' + encrypt.encryptUrlData(),
  })
  if (res.ok) {
    if (res.entityList.length > 0) {
      res.entityList.forEach((item) => {
        item.haveNum = 0
      })
    }
    return res.entityList
  }
}

const findQuestionById = async (questionId) => {
  try {
    return $.Axios({
      url:
        '/admin/question/findQuestionById?' +
        encrypt.encryptUrlData({ questionId }),
    })
  } catch (error) {
    console.error(error)
  }
}

const getPaperQuestion = async (examId) => {
  // 获取 试卷下的所有试题 Get all the test questions under the test paper
  let res = await $.Axios({
    url:
      '/admin/eq/findExamQuestionByExamId?' +
      encrypt.encryptUrlData({ examId }),
  })

  if (res.ok) {
    let list = []
    res.entityList.forEach((item) => {
      let dataObj = {}
      dataObj.questionId = item?.question?.questionId
      dataObj.questionsId = item?.question.questionsId
      dataObj.knowledgeTreeIds = item?.question?.knowledgeTreeIds
      dataObj.type = item?.question?.type
      dataObj.content = item?.question?.content
      dataObj.questionContent = item?.question?.questionContent
      dataObj.onlyKey = item?.question?.onlyKey
      dataObj.branchId = item?.question?.branchId
      dataObj.analysis = item?.question?.analysis
      dataObj.difficulty = item?.question?.difficulty
      dataObj.questionTypeName = item?.question?.questionTypeName
      dataObj.sourceType = item?.question?.sourceType
      dataObj.statement = item?.question?.statement
      dataObj.statementCurrent = item.statement || item.question?.statement
      dataObj.grade = item?.question?.grade
      dataObj._isAddState = true
      dataObj._source = item?.question?.sourceType
      dataObj._uniqueId = 'school' + item?.question?.questionId
      dataObj.isOptionOrder = item.isOptionOrder === 0 ? true : false
      if (item?.question?.type != 'synthesize') {
        dataObj._current_score =
          item.scoreData.scoreMap[item?.question?.onlyKey]
      } else {
        if (item?.question?.questionContent.questionList) {
          item?.question?.questionContent.questionList.forEach((e) => {
            e._score = item.scoreData.scoreMap[e.onlyKey]
          })
        }
      }
      if (item?.question?.type == 'blanks') {
        dataObj.title = item?.question?.title.replace(
          /{blankContent}/g,
          '______',
        )
      } else {
        dataObj.title = item?.question?.title
      }

      list.push(dataObj)
    })
    return list
  }
}

const getMixExamQuestion = async (mixExamId) => {
  let res = await $.Axios({
    url:
      '/admin/eq/findExamQuestionByMixExamId?' +
      encrypt.encryptUrlData({ mixExamId }),
    method: 'get',
  })
  return res
}

// CREATE QUESTION
const createQuestion = async (paramsData) => {
  if (!validBlank(paramsData)) return
  let res = await $.Axios({
    url:
      '/admin/question/createQuestion?' +
      encrypt.encryptGetSign({}, paramsData),
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(paramsData),
    transformRequest(data) {
      return data
    },
  })
  return res
}

// UPDATE QUESTION
const updateQuestion = async (paramsData) => {
  // update Question in exam
  if (!validBlank(paramsData)) return
  const res = await $.Axios({
    url:
      '/admin/question/updateQuestion?' +
      encrypt.encryptGetSign({}, paramsData),
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(paramsData),
    transformRequest(data) {
      return data
    },
  })
  return res
}

const updateQuestionPersonal = async (paramsData) => {
  //update question in teacher personal question bank
  if (!validBlank(paramsData)) return
  const res = await $.Axios({
    url:
      '/admin/question/updateQuestionPersonal?' +
      encrypt.encryptGetSign({}, paramsData),
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(paramsData),
    transformRequest(data) {
      return data
    },
  })
  return res
}

const updateQuestionByInitExam = async (paramsData) => {
  // update question when create exam
  if (!validBlank(paramsData)) return
  const res = await $.Axios({
    url:
      '/admin/question/updateQuestionByInitExam?' +
      encrypt.encryptGetSign({}, paramsData),
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(paramsData),
    transformRequest(data) {
      return data
    },
  })
  return res
}

const updateBossQuestion = async (paramsData) => {
  if (!validBlank(paramsData)) return
  let res = await $.Axios({
    url:
      '/admin/question/updateBossQuestion?' +
      encrypt.encryptGetSign({}, paramsData),
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(paramsData),
    transformRequest(data) {
      return data
    },
  })
  return res
}

const findBossQuestionByKnowledgetreeIdList = async (
  params,
  payload,
  timeout,
) => {
  try {
    const { pageInfo } = await $.Axios({
      url:
        '/admin/question/findBossQuestionByKnowledgetreeIdList?' +
        encrypt.encryptGetSign(params, payload),
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify(payload),
      transformRequest(data) {
        return data
      },
      timeout,
    })

    return pageInfo
  } catch (error) {
    console.log('findBossQuestionByKnowledgetreeIdList error: ', error)
    return {
      list: [],
      total: 0,
    }
  }
}

const findQuestionByKnowledgetreeIdList = async (params, payload, timeout) => {
  try {
    const { pageInfo } = await $.Axios({
      url:
        '/admin/question/findQuestionByKnowledgetreeIdList?' +
        encrypt.encryptGetSign(params, payload),
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify(payload),
      transformRequest(data) {
        return data
      },
      timeout,
    })

    return pageInfo
  } catch (error) {
    console.log('findQuestionByKnowledgetreeIdList error: ', error)
    return {
      list: [],
      total: 0,
    }
  }
}

const syncBossQuestion = async (payload, timeout = 20000) => {
  try {
    return $.Axios({
      url:
        '/admin/question/synchronizationBossQuestions?' +
        encrypt.encryptGetSign({}, payload),
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify(payload),
      transformRequest(data) {
        return data
      },
      timeout,
    })
  } catch (error) {
    console.log('syncBossQuestion error', error)
  }
}

const syncManyQuestionsToTeacherBank = (payload, timeout = 10000) => {
  return $.Axios({
    url:
      '/admin/question/synchronizationTeacherQuestions?' +
      encrypt.encryptGetSign({}, payload),
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(payload),
    transformRequest(data) {
      return data
    },
    timeout,
  })
}

const deleteQuestion = async (questionIds) => {
  try {
    return $.Axios({
      url:
        '/admin/question/deleteQuestion?' +
        encrypt.encryptUrlData({ questionIds }),
    })
  } catch (error) {
    console.log('deleteQuestion error', error)
  }
}

export default {
  createQuestion,
  getMixExamQuestion,
  updateBossQuestion,
  updateQuestion,
  updateQuestionPersonal,
  updateQuestionByInitExam,
  getQuestionTypeList,
  getPaperQuestion,
  findBossQuestionByKnowledgetreeIdList,
  findQuestionByKnowledgetreeIdList,
  syncBossQuestion,
  deleteQuestion,
  syncManyQuestionsToTeacherBank,
  findQuestionById,
}

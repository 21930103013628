import JSEncrypt from 'jsencrypt'
import PUBLIC_KEY from '@/assets/js/keyMap'
class encrypt {
  encryptData(data) {
    //加密数据 Encrypt the data
    data = data || {} //接收传过来的数据 Receive incoming data
    data.requestClient = 'admin' //请求终端类型，后台=admin，前台=front，手机端=mobile  Request terminal type: background =admin, front =front, mobile =mobile
    data.timestamp = new Date().getTime() + '' //获取当前时间戳  Gets the current timestamp
    data.randomStr = this.getRandomNumber(32) //32位的随机值 32-bit random value
    data.token = $.getCookie('accessToken') ? $.getCookie('accessToken') : '' //用户登录Token  User login Token
    data.requestIp = localStorage.getItem('Ip')
      ? localStorage.getItem('Ip')
      : '' //终端IP地址  Terminal IP Address
    // data.lang = sessionStorage.getItem('useLang') ? sessionStorage.getItem('useLang') : 'zh_cn';
    data.lang = sessionStorage.getItem('useLang')
      ? sessionStorage.getItem('useLang')
      : 'vi'
    data.tenantId =
      localStorage.getItem('adminInfo') &&
      JSON.parse(localStorage.getItem('adminInfo')).schoolId
        ? JSON.parse(localStorage.getItem('adminInfo')).schoolId
        : ''
    let keysArr = Object.keys(data).sort()
    let sortObj = {}
    let strParams = ''
    for (var i in keysArr) {
      sortObj[keysArr[i]] = data[keysArr[i]]
      strParams += keysArr[i] + '=' + data[keysArr[i]] + '&'
    }
    data.sign = this.encryptParams(md5(strParams)) //获取签名 To get the signature
    return data
  }

  encryptGetSignNull() {
    return this.encryptGetSign({}, {})
  }

  encryptGetSign(data, paramsData) {
    data = data || {} //接收传过来的数据 Receive incoming data
    paramsData = paramsData || {}
    data = this.removePropertyOfNull(data)
    paramsData = this.removePropertyOfNull(paramsData)
    data.requestClient = 'admin' //请求终端类型，后台=admin，前台=front，手机端=mobile Request terminal type: background =admin, front =front, mobile =mobile
    data.timestamp = new Date().getTime() + '' //获取当前时间戳  Gets the current timestamp
    data.randomStr = this.getRandomNumber(32) //32位的随机值 32-bit random value
    data.token = $.getCookie('accessToken') ? $.getCookie('accessToken') : '' //用户登录Token User login Token
    data.requestIp = localStorage.getItem('Ip')
      ? localStorage.getItem('Ip')
      : '' //终端IP地址 Terminal IP Address
    // data.lang = sessionStorage.getItem('useLang') ? sessionStorage.getItem('useLang') : 'zh_cn';
    data.lang = sessionStorage.getItem('useLang')
      ? sessionStorage.getItem('useLang')
      : 'vi'
    data.tenantId =
      localStorage.getItem('adminInfo') &&
      JSON.parse(localStorage.getItem('adminInfo')).schoolId
        ? JSON.parse(localStorage.getItem('adminInfo')).schoolId
        : ''
    let keysArr = Object.keys(data).sort()
    let sortObj = {}
    let strParams = ''
    for (var i in keysArr) {
      sortObj[keysArr[i]] = data[keysArr[i]]
      strParams += keysArr[i] + '=' + data[keysArr[i]] + '&'
    }
    let specialStr = strParams + JSON.stringify(paramsData)
    data.sign = this.encryptParams(md5(specialStr)) //获取签名 To get the signature
    let urlData = this.objectToParamString(data)
    return urlData
  }

  encryptParams(data) {
    //加密参数  Encryption parameters
    data = typeof data == 'object' ? JSON.stringify(data) : data
    let encryptor = new JSEncrypt()
    encryptor.setPublicKey(PUBLIC_KEY)
    return encryptor.encrypt(data)
  }

  getRandomNumber(digit) {
    let chars = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
    ]
    let randomNumber = ''
    for (let i = 0; i < digit; i++) {
      let id = parseInt(Math.random() * 61)
      randomNumber += chars[id]
    }
    return randomNumber
  }

  encryptUrlData(data) {
    data = data || {}
    data = this.removePropertyOfNull(data)
    let newData = this.encryptData(data)
    let urlData = this.objectToParamString(newData)
    return urlData
  }

  objectToParamString(paramObj) {
    //对象转参数字符串 Object to parameter string
    if (!paramObj) {
      return ''
    }
    let paramList = []
    Object.keys(paramObj) &&
      Object.keys(paramObj).forEach((key) => {
        let val = paramObj[key]
        if (val.constructor === Array) {
          val.forEach((_val) => {
            paramList.push(key + '=' + encodeURIComponent(_val)) //转URI
          })
        } else {
          paramList.push(key + '=' + encodeURIComponent(val)) //转URI
        }
      })
    return paramList.join('&')
  }

  removePropertyOfNull(obj) {
    Object.keys(obj).forEach((item) => {
      if (obj[item] == null || obj[item] == undefined) {
        delete obj[item]
      }
    })
    return obj
  }
}

export default new encrypt()

export default {
  state: {
    isCollapse: false, // 控制菜单展开与折叠 Controls menu expansion and collapse
    // menuList: JSON.parse(window.sessionStorage.getItem('menuList'))
  },
  mutations: {
    setMenuCollapse(state) {
      //设置菜单展开与折叠 Set menu expansion and collapse
      state.isCollapse = !state.isCollapse
    },
    widthSetByMenuCollapse(state, status) {
      state.isCollapse = status
    },
  },
}

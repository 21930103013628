<template>
  <div class="message_box" @click="goMessage" v-if="showMessage">
    <div class="message_img_box">
      <img src="../../../assets/image/top_login02.png" />
      <div class="tips_spot" v-if="unreadMsgNum > 0"></div>
    </div>
    <span>{{ $t('headerTxt.message') }}</span>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
export default {
  setup() {
    const { proxy } = getCurrentInstance()
    const router = useRouter()
    const route = useRoute()
    const userType = JSON.parse(
      window.localStorage.getItem('adminInfo'),
    ).userType
    let unreadMsgNum = ref(0)
    let showMessage = ref(true)

    const goMessage = () => {
      if (userType == 2) {
        router.push('/admin/message')
      }
      if (userType == 3) {
        router.push('/admin/teacher/message')
      }
    }

    const getSurveyNun = async () => {
      let paramsData = { readState: 0 }
      let res = await $.Axios({
        url:
          '/admin/auth/generalNotice/countGeneralNoticeNum?' +
          encrypt.encryptGetSign({}, paramsData),
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(paramsData),
        transformRequest(data) {
          return data
        },
      })
      if (res.ok) {
        unreadMsgNum.value += res.entity
      }
    }

    const getSystemNun = async () => {
      let paramsData = { readState: 0 }
      let res = await $.Axios({
        url:
          '/admin/auth/systemNoticeRecord/countSystemNoticeRecordNum?' +
          encrypt.encryptGetSign({}, paramsData),
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(paramsData),
        transformRequest(data) {
          return data
        },
      })
      if (res.ok) {
        unreadMsgNum.value += res.entity
      }
    }

    const getUnreadMsgFun = async () => {
      // 获取未读未读消息方法 Get unread unread message method
      if (userType == 3) {
        getSystemNun()
        getSurveyNun()
      }
    }
    getUnreadMsgFun()

    const setShowMessageFun = () => {
      switch (route.path) {
        case '/admin/teacher/message':
          showMessage.value = false
          break
        case '/admin/message':
          showMessage.value = false
          break
        case '/admin/message/info':
          showMessage.value = false
          break
        case '/admin/message/add':
          showMessage.value = false
          break
        case '/admin/message/update':
          showMessage.value = false
          break
        default:
          showMessage.value = true
          break
      }
    }
    setShowMessageFun()

    return {
      goMessage,
      unreadMsgNum,
      showMessage,
    }
  },
}
</script>

<style></style>

<template>
  <div class="user_card_box">
    <div class="message_user_cont">
      <message></message>
      <el-dropdown
        trigger="click"
        @command="onCommand"
        @visible-change="dropdownChangeFun">
        <div class="user_img_name_opt">
          <div class="img_name_box">
            <div class="img_box">
              <img
                v-if="userInfo.userImg"
                :src="getFileUrl(userInfo.userImg)"
                @error="errorUserImg($event)"
                alt="" />
              <i v-else class="iconfont icon-user1"></i>
            </div>
            <p
              :title="
                userInfo && userInfo.userName ? userInfo.userName : 'admin'
              ">
              {{ userInfo && userInfo.userName ? userInfo.userName : 'admin' }}
            </p>
          </div>
          <i
            class="iconfont"
            :class="dropdownChange ? 'icon-xiangshang1' : 'icon-xiangxia1'"></i>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="popper-dropdown-menu-user">
            <el-dropdown-item v-if="userType == 3" command="personalInfo">
              <i class="iconfont icon-user"></i>
              {{ $t('headerTxt.personalInfo') }}
            </el-dropdown-item>
            <el-dropdown-item command="updatePwd">
              <i class="iconfont icon-suo1"></i>
              {{ $t('headerTxt.updatePwd') }}
            </el-dropdown-item>
            <el-dropdown-item v-if="userType == 3" command="termsService">
              <i class="iconfont icon-fuwutiaokuan"></i>
              {{ $t('headerTxt.termsService') }}
            </el-dropdown-item>
            <el-dropdown-item command="exit">
              <i class="iconfont icon-tuichu"></i>
              {{ $t('headerTxt.logout') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <div class="log__box">
      <el-dialog
        :title="$t('headerTxt.updatePwd')"
        v-model="updatePwdMask"
        width="450px"
        :before-close="closeUpdatePwdMask">
        <el-form
          label-position="top"
          :rules="pwdRules"
          :model="pwdData"
          ref="pwdForm">
          <el-form-item :label="$t('headerTxt.oldPassword')" prop="oldPwd">
            <el-input
              v-model="pwdData.oldPwd"
              :type="showPwd.oldPwd ? 'text' : 'password'">
              <template #suffix>
                <i
                  class="iconfont"
                  :class="showPwd.oldPwd ? 'icon-eye2' : 'icon-eye-close'"
                  @click="showPwd.oldPwd = !showPwd.oldPwd"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('headerTxt.newPassword')" prop="password">
            <el-input
              v-model="pwdData.password"
              :type="showPwd.password ? 'text' : 'password'">
              <template #suffix>
                <i
                  class="iconfont"
                  :class="showPwd.password ? 'icon-eye2' : 'icon-eye-close'"
                  @click="showPwd.password = !showPwd.password"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('userTxt.confirmPwdTxt')" prop="verPwd">
            <el-input
              v-model="pwdData.verPwd"
              :type="showPwd.verPwd ? 'text' : 'password'">
              <template #suffix>
                <i
                  class="iconfont"
                  :class="showPwd.verPwd ? 'icon-eye2' : 'icon-eye-close'"
                  @click="showPwd.verPwd = !showPwd.verPwd"></i>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span>
            <el-button size="small" @click="closeUpdatePwdMask">
              {{ $t('publicTxt.cancelBtnTxt') }}
            </el-button>
            <el-button
              size="small"
              :disabled="
                !(pwdData.oldPwd && pwdData.password && pwdData.verPwd)
              "
              type="primary"
              @click="updateUserPassword">
              {{ $t('publicTxt.confirmBtnTxt') }}
            </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import useUserCard from './js/userCard'
import Message from './menuParts/Message'
export default {
  props: {
    status: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    let {
      userInfo,
      userType,
      onCommand,
      errorUserImg,
      outLogin,
      updatePwdMask,
      showPwd,
      updateUserPassword,
      closeUpdatePwdMask,
      dropdownChange,
      dropdownChangeFun,
      pwdRules,
      pwdData,
    } = useUserCard()

    return {
      userInfo,
      userType,

      onCommand,
      errorUserImg,
      outLogin,
      updatePwdMask,
      showPwd,
      updateUserPassword,
      closeUpdatePwdMask,
      dropdownChange,
      dropdownChangeFun,
      pwdRules,
      pwdData,
    }
  },
  components: {
    Message,
  },
}
</script>

<style lang="scss">
.user_card_box {
  color: white;
  font-size: 16px;
  .message_user_cont {
    display: flex;
    align-items: center;
    .message_box {
      display: flex;
      align-items: center;
      margin-right: 24px;
      cursor: pointer;
      .message_img_box {
        flex-shrink: 0;
        width: 40px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        margin-right: 10px;
        position: relative;
        & > img {
          width: 100%;
          border-radius: 50%;
          margin: auto;
          display: block;
        }
        .tips_spot {
          width: 10px;
          height: 10px;
          background: #f00;
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 50%;
        }
      }
    }
    .user_img_name_opt {
      font-size: inherit;
      font-family: inherit;
      width: 100%;
      // height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      cursor: pointer;
      .img_name_box {
        display: flex;
        align-items: center;
        .img_box {
          width: 40px;
          aspect-ratio: 1/1;
          margin-right: 10px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #eee;
          border-radius: 50%;
          & > i {
            font-size: 35px;
            color: #aaaaaa;
          }
        }
        p {
          font-weight: 500;
          color: white;
          font-size: 16px;
          white-space: nowrap;
          margin-right: 10px;
        }
      }
      & > i {
        font-weight: bold;
        color: #fff;
      }
    }
  }

  .log__box {
    .el-dialog {
      &__footer {
        & > span {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    input.el-input__inner {
      padding-right: 34px;
    }

    .iconfont {
      right: 10px;
      cursor: pointer;
    }
  }
}
.el-dropdown-menu__item {
  font-size: 14px;
  font-weight: 400;
  color: #182537;
}
</style>
